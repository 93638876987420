<template>
  <v-container class="fast-search">
    <CategoryTitle :category="category" />

    <div class="search-result" v-for="(q, index) in query" :key="index">
      <ProductListSlider
        :query="q"
        :paginationClass="'swiper-pagination-' + index"
        cols="auto"
        sm="auto"
        md="auto"
        lg="auto"
      >
        <template #title>
          <div
            class="searched-word-header d-flex align-center flex-column flex-sm-row mb-5 mb-sm-2"
          >
            <div class="searched-word-title secondary--text">
              <h3
                class="text-h4"
                v-html="$t('fastSearch.searchResult', [q])"
              ></h3>
            </div>
            <v-spacer />
            <div>
              <router-link :to="{ name: 'Search', query: { q: q } }">
                MOSTRA TUTTI I PRODOTTI
              </router-link>
            </div>
          </div>
        </template>
      </ProductListSlider>
      <!-- <v-divider class="mb-6"></v-divider> -->
    </div>
  </v-container>
</template>
<style scoped lang="scss">
.search-result {
  padding-bottom: 15px;
  margin-bottom: 18px;
  border-bottom: 1px solid var(--v-grey-lighten1);
}
</style>
<script>
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";

// import { mapProposal } from "~/service/ebsn.js";

import categoryMixins from "~/mixins/category";

export default {
  name: "FastSearch",
  components: {
    ProductListSlider,
    CategoryTitle
  },
  mixins: [categoryMixins],
  data() {
    return {
      query: []
    };
  },
  // computed: {
  //   ...mapProposal({
  //     headerBanner: "header-banner",
  //     footerBanner: "footer-banner"
  //   })
  // },
  methods: {
    getQueryParams() {
      this.query = this.$route.query.q;
    }
  },
  async created() {
    this.getQueryParams();
  },
  watch: {
    async "$route.query"() {
      this.getQueryParams();
    }
  }
};
</script>
